<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{$t('general.generados')}}</h3>    
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>        
        </div>
        <div class="card-body">
            <facturacionfactura :eliminable="eliminablefact" v-for="factura in datosfactura" :togglecargar="togglecargar" :key="factura.numero" :factura="factura" :id="id" :servicio="servicio"/>
            <facturacionalbaran :eliminable="eliminablealb" v-for="albaran in datosalbaran" :togglecargar="togglecargar"  :key="albaran.numero" :albaran="albaran" :id="id" :servicio="servicio"/>
            <facturacionpresupuesto  v-for="presupuesto in datospresupuesto" :togglecargar="togglecargar" :key="presupuesto.presupuesto.presupuestos_id_presupuesto" :presupuesto="presupuesto" :id="id" :servicio="servicio"/>
            <facturacionpagos :eliminable="eliminablepago" v-for="pagos in datospagos" :key="pagos" :id="id" :pagos="pagos" :servicio="servicio" :togglecargar="togglecargar"/>
        </div>
    </div>
</template>
<script>
import facturacionfactura from './FacturacionListado/FacturacionListadoFactura.vue';
import facturacionalbaran from './FacturacionListado/FacturacionListadoAlbaran.vue';
import facturacionpresupuesto from './FacturacionListado/FacturacionListadoPresupuesto.vue';
import facturacionpagos from './FacturacionListado/FacturacionListadoPagos.vue';
import { PwgsApi } from '../../../../services/PwgsApi';
//import $ from 'jquery';
//import Dialog from 'primevue/dialog';
export default {
    props: ['id', 'servicio','togglecargar'],
    components: {
        facturacionfactura,
        facturacionalbaran,
        facturacionpresupuesto,
        facturacionpagos,
    },    
    data() {
        return {
            mostrarModulo: false,
            datosfactura: '',
            datosalbaran: '',
            datospresupuesto: '',
            datospagos:'',
            eliminablefact:false,
            eliminablealb:false,
            eliminablepago:false,
        }
    },
    methods: {
        reload(){
            this.cargardatosFactura();
            this.cargardatosAlbaran();
            this.cargardatosPresupuesto();
            this.cargarPagos();
            console.log('reloddd');
        },
        async cargardatosFactura() { 
            //GET  modulos/pwgsapi/index.php/facturas/:id_servicio
            const api = new PwgsApi();
            this.datosfactura = '';
            this.datosfactura = await api.get('facturas/' + this.$props.id + '/servicio');
            if(this.datosfactura){
                this.eliminablefact = this.datosfactura[0].eliminable.estado == 1;
            }
        },
        async cargarPagos() {
            // GET /facturas/:id_servicio/pagos
            const api = new PwgsApi();
            this.datospagos = await api.get('facturas/' + this.$props.id + '/pagos');
            if(this.datospagos){
                this.eliminablepago = this.datospagos[0].eliminable.estado == 1;
            }
        },
       async cargardatosAlbaran() { 
            //GET  modulos/pwgsapi/index.php/facturas/:id_servicio/albaranes
            const api = new PwgsApi();
            this.datosalbaran = await api.get('facturas/' + this.$props.id + '/albaranes');
            if(this.datosalbaran){
                console.log('alvas',this.datosalbaran);
                this.eliminablealb = this.datosalbaran[0].eliminable.estado == 1;
            }
        },
        async cargardatosPresupuesto() { 
           //GET  modulos/pwgsapi/index.php/presupuestos/:id_servicio/presupuestos-generados
            const api = new PwgsApi();
            this.datospresupuesto = await api.get('presupuestos/' + this.$props.id + '/presupuestos-generados');
        },
    },
    mounted() {
        this.cargardatosFactura();
        this.cargardatosAlbaran();
        this.cargardatosPresupuesto();
        this.cargarPagos();
    },
    watch: {
        id() {
            this.cargardatosAlbaran();
            this.cargardatosFactura();
            this.cargardatosPresupuesto();
            this.cargarPagos();
        },
        togglecargar() {
            this.cargardatosAlbaran();
            this.cargardatosFactura();
            this.cargardatosPresupuesto();
            this.cargarPagos();
        }
    }
}
</script>