<template>
    <div class="row">
        <div class="col-md-12">
            <div class="callout callout-warning">
                <div class="ribbon_no_cobrada ribbon-top-right" v-if="!presupuesto.presupuesto.cobrada"><span>{{$t('general.no aprobado')}}</span></div>
                <h5>{{$t('general.presupuesto')}} <strong>{{presupuesto.presupuesto.prefijo}}-{{presupuesto.numero}}</strong></h5>
                <div class="row">
                    <div class="col-md-4"><input type="text" class="form-control input-xs" :value="presupuesto.presupuesto.fecha_hoy"></div>
                    <div class="col-md-3"><h4>{{presupuesto.presupuesto.total}}&euro;</h4></div>
                    <div class="col-md-5">
                        <button type="button" :onclick="datos" class="btn btn-default btn-flat" ><i class="fas fa-file-invoice"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>
<style scoped>
/* common */
.ribbon_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_cobrada::before,
.ribbon_cobrada::after {
  position: absolute;
  z-index: -1;
  content: ''; 
  display: block;
  border: 5px solid #2980b9;
}
.ribbon_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #2980b9;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}

.ribbon_no_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_no_cobrada::before,
.ribbon_no_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #c42020;
}
.ribbon_no_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #d32c42;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}


/* top right*/
.ribbon-top-right {
  top: -8px;
  right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}


</style>
<script>



export default {
      props: ['id', 'servicio','presupuesto','togglecargar'],

    data() {
        return {
          mostrarModulo: false,
          datos: "",
          url: "",
        }
    },
    methods: {
   
      cargardatos() {
        this.url = this.$props.presupuesto.url_descarga_pdf.slice(5);
        this.datos = this.datos + this.url + "'";
         }
  },
  mounted() { 
    let aux = localStorage.getItem('direccion');
    this.datos = "location.href='https://" + aux;
    this.cargardatos();
  },
  watch: {
    togglecargar(){
      this.cargardatos();
    }
  }
}
</script>