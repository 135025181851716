<template>
    <section class="content" style="margin-top: -20px;">
        <div class="row">
            <div class="col-md-8">
                <facturacionfacturar ref="fac" :id="id" :servicio="servicio" :ffac="ffac" />
            </div>
            <div class="col-md-4" v-if="servicioext">
                <div class="card" v-if="servicioext.datos.estado!='finalizado'">
                    <div class="card-header bg-gray">
                        <h3 class="card-title"><i class="fas fa-sign-out-alt mr-2"></i>No {{$t('general.facturar')}}
                        </h3>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <button class="btn btn-block btn-info" @click="finalizarSinFacturar()"
                            type="button">{{$t('general.finalizar sin factura')}}</button>
                    </div>
                </div>
                <facturacionlistado ref="listado" :togglecargar="togglecargar" :id="id" :servicio="servicio" />
            </div>
        </div>
    </section>
</template>
<script>
//Iconos en https://fontawesome.com/v5/search?m=free&s=solid%2Cbrands
import { PwgsApi } from '../../../../services/PwgsApi';
import facturacionfacturar from './FacturacionFacturar.vue'
import facturacionlistado from './FacturacionListado.vue'
//import Dialog from 'primevue/dialog'; 
export default {
    props: ['id', 'servicio', 'ffac','servicioext'],
    components: {
        facturacionfacturar,
        facturacionlistado,

},
    data() {
        return {
            togglecargar: 0,
        }
    },
    methods: {
        async finalizarSinFacturar() { 
            //PUT  modulos/pwgsapi/index.php/facturas/:id_servicio/finalizar-sin-facturar
            try {
                const api = new PwgsApi();
                await api.put("facturas/" + this.$props.id + "/finalizar-sin-facturar");
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });

            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        reload(){
            console.log('reload1')
            this.$refs.fac.reload();
            this.$refs.listado.reload();
        },
        movertoggle() {
            console.log('entramovertoggle');
            this.togglecargar = this.togglecargar + 1;
            this.$emit('facturado');
        }
    },
    watch:{
    }
}
</script>